import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query FeedingHope {
    heroImage: file(relativePath: {eq: "project-feeding-hope-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Feeding Hope: We don't only provide them food.. with your support we feed them with hope." description="Hunger and the different types of malnutrition threaten the lives and health of millions of people around the world. Women and children are the hardest hit. Political instability, proliferation of violent conflicts, climate shocks, social problems are all reasons that can lead to hunger and malnutrition." mdxType="SEO" />
    <CauseHero purpose="feedinghope" title="Feeding Hope" text="We don't only provide them food.. with your support we feed them with hope." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <p>{`Hunger and the different types of malnutrition threaten the lives and health of millions of people around the world. Women and children are the hardest hit. Political instability, proliferation of violent conflicts, climate shocks, social problems are all reasons that can lead to hunger and malnutrition.`}</p>
      <p>{`FEEDING HOPE is one of the largest UMMATY humanitarian programs: we provide, `}<strong parentName="p">{`refugees`}</strong>{`, `}<strong parentName="p">{`homeless`}</strong>{`, and all types of `}<strong parentName="p">{`needy people`}</strong>{` with `}<strong parentName="p">{`assistance and support`}</strong>{` at the time of crisis to meet their most urgent needs.`}</p>
      <p>{`The aid provided is more than simple food rations: the populations' needs are meticulously assessed, in order to constitute `}<strong parentName="p">{`balanced food baskets`}</strong>{` in adequacy with the eating habits of each region. In addition to `}<strong parentName="p">{`food aid, sanitary kits, blankets`}</strong>{` and clothing are also distributed to meet urgent needs.`}</p>
      <p>{`Whether in mountains isolated by winter snow, in villages affected by the drought, homeless people in our streets, or in refugees' camps, UMMATY teams travel to be closest to the needy. Thanks to your donations, we give them hope and remind them that they have brothers and sisters around the world who support them and continue to work to offer them a better future.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      